<template>
  <v-container class="window" style="margin-left:11%; margin-right:11%; width: 78%;">
    <v-data-table :headers="headers" :items="userData" sort-by="calories" class="elevation-1">
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Master Tarif Kwitansi</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-dialog v-model="dialog" max-width="600px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="success" dark class="mb-2" v-bind="attrs" v-on="on" @click="reset">
                Tambah Tarif Kwitansi
              </v-btn>
            </template>
            <v-card>
              <v-toolbar color="primary" dark>{{ formTitle }}</v-toolbar>
              <v-card-text>
                <v-container>
                  <v-alert dense dismissible text type="warning" v-if="validation.kode">
                    {{ validation.kode[0] }}
                  </v-alert>
                  <v-alert dense dismissible text type="warning" v-if="validation.jenis_kwt">
                    {{ validation.jenis_kwt[0] }}
                  </v-alert>
                  <v-alert dense dismissible text type="warning" v-if="validation.tarif">
                    {{ validation.tarif[0] }}
                  </v-alert>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.KODE" label="Kode Kwitansi"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.JENIS_KWT" label="Jenis Kwitansi"></v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field v-model="editedItem.TARIF" label="Tarif Kwitansi"></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="close">
                  Cancel
                </v-btn>
                <v-btn color="blue darken-1" text @click="save">
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogDelete" max-width="500px">
            <v-card>
              <v-card-title class="text-h5">Apakah anda ingin menghapus data ini?</v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="blue darken-1" text @click="closeDelete">Batal</v-btn>
                <v-btn color="blue darken-1" text @click="deleteItemConfirm">Hapus</v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-toolbar>
        <v-divider></v-divider>
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon small @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
      <!-- <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
            >
              Reset
            </v-btn>
          </template> -->
    </v-data-table>
  </v-container>
</template>

<script>
import axios from 'axios';
import { mapGetters } from 'vuex'
export default {
  data: () => ({
    dialog: false,
    dialogDelete: false,
    headers: [
      { text: 'Kode Kwitansi', value: 'KODE' },
      { text: 'Jenis Kwitansi', value: 'JENIS_KWT' },
      { text: 'Tarif (PPh)%', value: 'TARIF' },
      { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    editedItem: {},
    userData: [],
    userUpdate: {},
    validation: []
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'Tambah Master Tarif Kwitansi' : 'Edit Master Tarif Kwitansi'
    },
    ...mapGetters({
      user: 'user',
    })
  },

  watch: {
    dialog(val) {
      val || this.close()
    },
    dialogDelete(val) {
      val || this.closeDelete()
    },
  },

  created() {
    let uri = `/getmastertarifkwt`;
    axios.get(uri).then(response => {
      this.userData = response.data.data;
      // console.log(this.userData);
    });
  },

  methods: {
    editItem(item) {
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // console.log(this.editedItem);
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.userData.indexOf(item)
      this.editedItem = Object.assign({}, item)
      // this.dialogDelete = true

      this.$swal.fire({
        title: 'Apakah anda ingin menghapus data ini?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Hapus'
      }).then((result) => {
        if (result.isConfirmed) {
          axios({
            method: 'delete',
            url: 'deletemastertarifkwt',
            data: {
              id: this.editedItem.ID
            },
          })
            .then(() => {
              let uri = `getmastertarifkwt`;
              axios.get(uri).then(response => {
                this.userData = response.data.data;
              });
              this.$swal.fire(
                'Sukses!',
                'Data Berhasil dihapus',
                'success'
              )
              this.closeDelete()
            })
            .catch(error => {
              console.log(error.response)
              this.$swal.fire(
                'Gagal!',
                'Data Gagal dihapus',
                'warning'
              )
            })
        }
      })
    },
    deleteItemConfirm() {
      axios.delete(`v1/deleterole/${this.editedItem.id}`)
        .then(() => {
          let uri = `v1/roles`;
          axios.get(uri).then(response => {
            this.userData = response.data.data;
          });
        }).catch((error) => {
          alert(error);
        });
      this.closeDelete()
    },

    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete() {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    reset() {
      this.validation = [];
      this.validation.splice(0);
    },
    save() {
      if (this.editedIndex > -1) {
        this.$swal.fire({
          title: 'Apakah anda ingin mengupdate data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Update'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'updatemastertarifkwt',
              data: {
                dataKwt: this.editedItem,
                id: this.editedItem.ID
              }
            })
              .then(() => {
                let uri = `getmastertarifkwt`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di update!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal diupdate',
                  'warning'
                )
              })
          }
        })
      } else {
        this.$swal.fire({
          title: 'Apakah anda ingin menambahkan data ini?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Tambah'
        }).then((result) => {
          if (result.isConfirmed) {
            axios({
              method: 'post',
              url: 'insertmastertarifkwt',
              data: {
                dataKwt: this.editedItem
              }
            })
              .then(() => {
                let uri = `getmastertarifkwt`;
                axios.get(uri).then(response => {
                  this.userData = response.data.data;
                });
                this.$swal.fire(
                  'Sukses!',
                  'Data berhasil di simpan!',
                  'success'
                )
                this.close();
              })
              .catch(error => {
                this.validation = error.response.data.data;
                this.$swal.fire(
                  'Gagal!',
                  'Data Gagal disimpan',
                  'warning'
                )
              })
          }
        })
      }
    },
  },

}
</script>